var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ws-index" }, [
    _vm.placedOrder !== null
      ? _c("div", { staticClass: "checkout-success" }, [
          _c("div", { staticClass: "header" }, [_vm._v("THANK YOU!")]),
          _vm._v(" "),
          _c("div", { staticClass: "body" }, [
            _c("div", [_vm._v("We've got your order.")]),
            _vm._v(" "),
            _c("div", { staticClass: "collection-time" }, [
              _vm._v(
                "\n        Please collect\n        " +
                  _vm._s(
                    _vm
                      .$formatFulfilmentSlotTime(_vm.placedOrder.fulfilmentSlot)
                      .toLowerCase()
                  ) +
                  "\n      "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "content-container" }, [
          _c(
            "div",
            { staticClass: "top-menu" },
            [
              _c(
                "ws-button",
                { attrs: { href: "/checkout", variant: "cta" } },
                [_vm._v("Order Now")]
              ),
              _vm._v(" "),
              _c(
                "ws-button",
                {
                  staticClass: "cart-button",
                  attrs: { href: "/cart", variant: "cta", borderless: "" },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "cart-shopping", size: "2x" },
                  }),
                  _vm._v(" "),
                  _vm.menuProductLinesInBag.length > 0
                    ? _c(
                        "svg",
                        {
                          staticClass: "cart-has-items",
                          attrs: {
                            viewBox: "0 0 100 100",
                            xmlns: "http://www.w3.org/2000/svg",
                            fill: "red",
                          },
                        },
                        [
                          _c("circle", {
                            attrs: { cx: "10", cy: "10", r: "10" },
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "category-menu" },
            [
              _c("ws-search-button-input", {
                model: {
                  value: _vm.searchText,
                  callback: function ($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText",
                },
              }),
              _vm._v(" "),
              _vm._l(_vm.menu.menu_display_groups, function (menuDisplayGroup) {
                return _c(
                  "div",
                  {
                    key: menuDisplayGroup.id,
                    staticClass: "web-display-group-link",
                    on: {
                      click: function ($event) {
                        return _vm.scrollToMenuDisplayGroup(menuDisplayGroup)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(menuDisplayGroup.name) +
                        "\n        "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "separator" }),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "slide-fade" } },
          [
            _vm.selectedMenuProduct !== null
              ? _c("ws-menu-product-modal", {
                  attrs: {
                    "menu-product": _vm.selectedMenuProduct,
                    "menu-product-lines-in-bag": _vm.menuProductLinesInBag,
                  },
                  on: {
                    "update:menuProductLinesInBag": function ($event) {
                      _vm.menuProductLinesInBag = $event
                    },
                    "update:menu-product-lines-in-bag": function ($event) {
                      _vm.menuProductLinesInBag = $event
                    },
                    close: _vm.closeModal,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "content-container" }, [
          _c(
            "div",
            { staticClass: "product-categories" },
            _vm._l(_vm.filteredMenuDisplayGroups, function (menuDisplayGroup) {
              return _c("div", { key: menuDisplayGroup.id }, [
                _c(
                  "div",
                  {
                    ref: function (ref) {
                      return (_vm.menuDisplayGroupRefs[menuDisplayGroup.name] =
                        ref)
                    },
                    refInFor: true,
                    staticClass: "product-category-name",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(menuDisplayGroup.name) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "menu-product-cards" },
                  _vm._l(
                    menuDisplayGroup.menu_products,
                    function (menuProduct) {
                      return _c("ws-menu-product-card", {
                        key: menuProduct.id,
                        attrs: {
                          "menu-product": menuProduct,
                          "is-in-bag": _vm.menuProductLinesInBag.some(function (
                            menuProductLineInBag
                          ) {
                            return menuProduct.menu_product_lines.some(
                              function (menuProductLine) {
                                return (
                                  menuProductLine.id ===
                                  menuProductLineInBag.menu_product_line_id
                                )
                              }
                            )
                          }),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.selectMenuProduct(menuProduct)
                          },
                        },
                      })
                    }
                  ),
                  1
                ),
              ])
            }),
            0
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("footer", { staticClass: "footer" }, [
      _c("img", {
        staticClass: "powered-by-logo",
        attrs: { src: _vm.PoweredByLittleTillWhite },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }