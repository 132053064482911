export default {
  install(Vue) {
    Vue.prototype.$reportError = (error) => {
      console.error(error);

      if (
        fetch === undefined ||
        window.errorsUrl === undefined ||
        window.errorsAppId === undefined
      ) {
        // These are logged in app.js in case of a configuration error.
        return;
      }

      fetch(window.errorsUrl, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        redirect: "follow", // manual, *follow, error
        body: JSON.stringify({
          app_id: window.errorsAppId,
          url: window.location.href,
          message: error.message,
          stack: error.stack,
        }),
      });
    };
  },
};
