<!-- TODO: Can we `provide` computed bagItems and bag modifier functions? -->
<template>
  <div class="ws-index">
    <div v-if="placedOrder !== null" class="checkout-success">
      <div class="header">THANK YOU!</div>
      <div class="body">
        <div>We&apos;ve got your order.</div>
        <div class="collection-time">
          Please collect
          {{
            $formatFulfilmentSlotTime(placedOrder.fulfilmentSlot).toLowerCase()
          }}
        </div>
      </div>
    </div>

    <div class="content">
      <div class="content-container">
        <div class="top-menu">
          <ws-button href="/checkout" variant="cta">Order Now</ws-button>

          <ws-button class="cart-button" href="/cart" variant="cta" borderless>
            <font-awesome-icon icon="cart-shopping" size="2x" />
            <svg
              v-if="menuProductLinesInBag.length > 0"
              class="cart-has-items"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
              fill="red"
            >
              <circle cx="10" cy="10" r="10" />
            </svg>
          </ws-button>
        </div>

        <div class="category-menu">
          <ws-search-button-input v-model="searchText" />
          <div
            :key="menuDisplayGroup.id"
            v-for="menuDisplayGroup in menu.menu_display_groups"
            @click="scrollToMenuDisplayGroup(menuDisplayGroup)"
            class="web-display-group-link"
          >
            {{ menuDisplayGroup.name }}
          </div>
        </div>
      </div>

      <div class="separator"></div>

      <transition name="slide-fade">
        <ws-menu-product-modal
          v-if="selectedMenuProduct !== null"
          :menu-product="selectedMenuProduct"
          :menu-product-lines-in-bag.sync="menuProductLinesInBag"
          @close="closeModal"
        />
      </transition>

      <div class="content-container">
        <div class="product-categories">
          <div
            :key="menuDisplayGroup.id"
            v-for="menuDisplayGroup in filteredMenuDisplayGroups"
          >
            <div
              class="product-category-name"
              :ref="
                (ref) => (menuDisplayGroupRefs[menuDisplayGroup.name] = ref)
              "
            >
              {{ menuDisplayGroup.name }}
            </div>
            <div class="menu-product-cards">
              <ws-menu-product-card
                :key="menuProduct.id"
                v-for="menuProduct in menuDisplayGroup.menu_products"
                :menu-product="menuProduct"
                @click="selectMenuProduct(menuProduct)"
                :is-in-bag="
                  menuProductLinesInBag.some((menuProductLineInBag) =>
                    menuProduct.menu_product_lines.some(
                      (menuProductLine) =>
                        menuProductLine.id ===
                        menuProductLineInBag.menu_product_line_id
                    )
                  )
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="footer">
      <img class="powered-by-logo" :src="PoweredByLittleTillWhite" />
    </footer>
  </div>
</template>

<script>
import PoweredByLittleTillWhite from "../../img/powered-by-little-till-white.png";

export default {
  props: {
    menu: {
      required: true,
      type: Object,
    },
    initialMenuProductLinesInBag: {
      required: true,
      type: Array,
    },
    placedOrder: {
      required: false,
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      searchText: "",
      selectedMenuProduct: null,
      menuProductLinesInBag: [...this.initialMenuProductLinesInBag],
      menuDisplayGroupRefs: {},
    };
  },
  computed: {
    PoweredByLittleTillWhite() {
      return PoweredByLittleTillWhite;
    },
    filteredMenuDisplayGroups() {
      if (this.searchText === "") {
        return this.menu.menu_display_groups;
      }

      return this.menu.menu_display_groups
        .map((menuDisplayGroup) => ({
          ...menuDisplayGroup,
          menu_products: menuDisplayGroup.menu_products.filter((menuProduct) =>
            menuProduct.product.name
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          ),
        }))
        .filter(
          (menuDisplayGroup) => menuDisplayGroup.menu_products.length > 0
        );
    },
  },
  methods: {
    scrollToMenuDisplayGroup(menuDisplayGroup) {
      this.menuDisplayGroupRefs[menuDisplayGroup.name].scrollIntoView({
        behavior: "smooth",
      });
    },
    selectMenuProduct(menuProduct) {
      this.selectedMenuProduct = menuProduct;

      // TODO: Clearing the search text is really a workaround for the fact
      // that due to z-index, it displays on top of the modal.
      this.searchText = "";

      history.pushState(null, null, `#${menuProduct.product.slug}`);
    },
    closeModal() {
      window.history.back();
      // this.selectedMenuProduct = null;
      // Remove the pushState from the URL.
    },
  },
  watch: {
    menuProductLinesInBag(bagItems) {
      this.$api.put(
        "bag/menu-product-lines",
        bagItems.map((bagItem) => ({
          ...bagItem,
          // null quantities are used for "declined option".
          options: bagItem.options.filter(
            (option) => option.quantity !== null && option.quantity > 0
          ),
        }))
      );
    },
  },
  mounted() {
    // Get the hash from the URL and open the modal if it exists.
    const hash = window.location.hash;
    if (hash !== "") {
      const menuProduct = this.menu.menu_display_groups
        .flatMap((menuDisplayGroup) => menuDisplayGroup.menu_products)
        .find((menuProduct) => menuProduct.product.slug === hash.slice(1));

      if (menuProduct !== undefined) {
        this.selectMenuProduct(menuProduct);
      } else {
        window.location.hash = "";
      }
    }

    // Close the modal when the popstate event is triggered.
    window.addEventListener("popstate", () => {
      this.selectedMenuProduct = null;
    });
  },
  async created() {},
};
</script>

<style lang="scss" scoped>
.ws-index {
  $footerHeight: 200px;

  .checkout-success {
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    text-align: center;

    width: 100vw;
    background-color: var(--light-grey);
    color: #00ff00;
    padding: 2rem;
    font-weight: 500;

    .header {
      color: #5d9682;
      font-size: 3rem;
      letter-spacing: 2px;
    }

    .body {
      font-size: 2rem;
      color: black;
      line-height: 1.6em;
      letter-spacing: 1px;

      .collection-time {
        margin-top: 1rem;
        opacity: 0.6;
        font-size: 1.5rem;
      }
    }
  }

  .content {
    min-height: calc(100vh - #{$footerHeight});

    padding-top: 2em;

    // Remove padding from content container, indent headers.
    $bp1: 500px;

    .content-container {
      max-width: 1024px;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 2em;

      @media (max-width: $bp1) {
        padding: 0;
      }
    }

    .top-menu {
      align-self: flex-end;
      display: flex;
      gap: 1em;
      margin-bottom: 1em;
    }

    .category-menu {
      position: relative;
      min-height: 4em;
      display: flex;
      align-items: center;
      font-size: 1em;
      font-weight: 500;
      gap: 1em;
      width: 100%;
      margin-bottom: 0.5rem;

      // TODO: Check if there's a better way than scrolling.
      overflow: scroll;

      .search-input {
        height: 35px;
        position: absolute;
        width: 100%;
      }
    }

    .separator {
      width: 100vw;
      border-bottom: 1px solid var(--light-grey);
    }

    .product-categories {
      display: flex;
      gap: 2em;
      flex-direction: column;
      width: fit-content;
      margin: 2em auto;

      .product-category-name {
        font-weight: 500;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        font-size: 1.3em;

        @media (max-width: $bp1) {
          margin-left: 1em;
        }
      }

      .menu-product-cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em 1.5em;

        @media (max-width: 768px) {
          grid-template-columns: 1fr;
        }
      }
    }

    .web-display-group-link {
      cursor: pointer;

      // Animate an underline on hover.
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: black;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }

      &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }

    .slide-fade-enter-active {
      transition: all 0.3s ease;
    }
    .slide-fade-leave-active {
      transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  .footer {
    min-height: $footerHeight;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      180deg,
      rgba(50, 59, 67, 100) 0%,
      rgba(54, 64, 75, 100) 0%
    );

    img.powered-by-logo {
      max-width: 80px;
    }
  }

  .cart-button {
    position: relative;

    .cart-has-items {
      color: red;
      position: absolute;
      top: -1px;
      left: 25px;
    }
  }
}
</style>
